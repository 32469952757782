import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../App';

const Snackbar = () => {

    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {

        if(state.snackbar.isVisible)
        setTimeout(() => {
            dispatch({ type: 'SNACKBAR', payload: { isVisible: false, message: '' } });
        }, 4000);

    }, [state.snackbar.isVisible, dispatch]);

    return (<div className={`snackbar isVisible--${String(state.snackbar.isVisible)}`}>
        <div className="snackbar__inner">
            {state.snackbar.message}
        </div>
    </div>);
};

export default Snackbar;