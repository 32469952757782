const shuffledDices = (state) => {

  const lockedDices = state.dices
    .filter(item => !item.active);

  // shuffle active
  const activeDices = state.dices
    .filter(item => item.active)
    .map((item) => {
      return {
        ...item,
        face: Math.floor(Math.random() * 6),
        rotation: Math.floor(Math.random() * 360)
      };
    });

  const firstRollDices = state.dices.map(item => {
    return {
      ...item,
      active: true,
      face: Math.floor(Math.random() * 6),
      rotation: Math.floor(Math.random() * 360)
    }
  });

  const dices = state.rollsLeft === 3 ?
    firstRollDices :
    [...lockedDices, ...activeDices];

  return dices;
};

export default shuffledDices;