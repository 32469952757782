import React, { useContext } from 'react';
import Scorecard from './scorecard';
import RollBtn from './roll_btn';
import Dices from './dices';
import Dashboard from './dashboard';
import { AppContext } from '../../App';
import Wow from '../extra/wow';

const Child = () => {

    const { state } = useContext(AppContext);

    const isStarted = state.rollsLeft < 3;
    const allLocked = state.dices.filter(item => !item.active).length === 5;
    const c_locked = `game--isStartedAllLocked-${String(isStarted && allLocked)}`;

    const c_rlsl = `game--rollsLeft-${state.rollsLeft}`;
    const c_rnsl = `game--roundsLeft-${state.roundsLeft}`;
    const c_bonus = `game--hasBonus-${String(state.scorecard.bonus === 35)}`;

    return (<div className={`game ${c_locked} ${c_rlsl} ${c_rnsl} ${c_bonus}`}>

        <section className="main_section_upper">
            <Scorecard />
        </section>

        <section className="main_section_lower">
            <Dices />
            <RollBtn />
        </section>

        <Dashboard />

        <Wow />

    </div>);
};

export default Child;