import React from 'react';

const faces = {
    f1: <svg className="face--1">
        <circle />
    </svg>,
    f2: <svg className="face--2">
        <circle /><circle />
    </svg>,
    f3: <svg className="face--3">
        <circle /><circle /><circle />
    </svg>,
    f4: <svg className="face--4">
        <circle /><circle /><circle /><circle />
    </svg>,
    f5: <svg className="face--5">
        <circle /><circle /><circle /><circle /><circle />
    </svg>,
    f6: <svg className="face--6">
        <circle /><circle /><circle /><circle /><circle /><circle />
    </svg>,
}

export default faces;