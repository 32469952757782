
const setWowVisible = (oldSc, newSc, scName) => {

    // some more for debugging 'toak', 'fh', 'ones', 'twos', 'threes', 'fours'
    const validName = ['yz', 'foak', 'ls'].includes(scName);

    const wow = (oldSc[scName] !== newSc[scName] && validName && newSc[scName] >= 25) ?
        { isVisible: true, name: scName } : false;

    const wowBonus = oldSc.bonus !== newSc.bonus ? { isVisible: true, name: 'bonus' } : false;

    return wow || wowBonus || { isVisible: false, name: '' };
};

export default setWowVisible;