import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../App';

const Wow = () => {

    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {

        if (state.wow.isVisible)
            setTimeout(() => {
                // sets wow isVisible false
                dispatch({ type: 'WOW' });
            }, 1500);

    }, [state.wow.isVisible, dispatch]);

    const nameMap = { ones: 'ones', twos: 'twos', threes: 'threes', fours: 'fours', fives: 'fives', sizes: 'sixes', bonus: 'bonus', toak: '3 of a kind', foak: 'four of a kind', fh: 'full house', ss: 'small straight', ls: 'large straight', yz: 'yahtzee', ch: 'chance' }

    return (
        <div className={`wow wow--isVisible-${state.wow.isVisible}`}>
            <div className="wow__inner">

                <div> {state.wow.name === 'foak' &&
                    <h3>Excellent</h3>}
                    <h2>{nameMap[state.wow.name]}!</h2>
                </div>

            </div>
        </div>
    );
};

export default Wow;