import React, { useReducer, createContext } from 'react';
import Game from './components/game/game';
import Results from './components/results/results';
import HowToPlay from './components/how_to_play/how_to_play';
import Settings from './components/settings/settings';
import gameState from './state/game';
import gameReducer from './reducers/game';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Snackbar from './components/extra/snackbar';
import Splash from './components/extra/splash';

export const AppContext = createContext();

const App = () => {

  const [state, dispatch] = useReducer(gameReducer, gameState);
  const colorfulIsOn = state.settings.colorfulIsOn;

  return (
    <Router>
      <AppContext.Provider value={{ state, dispatch }}>
        <div className={`app app--isColorful-${String(colorfulIsOn)}`}>
          <Switch>
            <Route path="/results">
              <Results />
            </Route>
            <Route path="/how-to-play">
              <HowToPlay />
            </Route>
            <Route path="/settings">
              <Settings />
            </Route>
            <Route path="/snack">
        
          </Route>
            <Route path="/">
            {!state.splash.isVisible && 
              <React.Fragment>
              <Game />
              <Snackbar/>
              </React.Fragment>
            }
            {state.splash.isVisible && 
              <Splash />
            }
              <p className="copyright">YAHTZEE APP ©</p>
            </Route>
          </Switch>
        </div>
      </AppContext.Provider>
    </Router>
  );
};

export default App;
