import React, { useContext } from 'react';
import { AppContext } from '../../App';
import { Link } from 'react-router-dom';
import ToggleBtn from './toggleBtn';

const Settings = () => {

    const { state, dispatch } = useContext(AppContext);

    return (
        <div className="settings_section settings">
            <h2>Settings</h2>

            <ul className="settings__grid">
                <li className="gi-1">Play sound</li>
                <li className="gi-2">
                    <ToggleBtn isOn={state.settings.soundIsOn} settingsPropName='soundIsOn' />
                </li>
                <li className="gi-3"></li>

                <li className="gi-4">Color Dice</li>
                <li className="gi-5">
                <ToggleBtn isOn={state.settings.colorfulIsOn} settingsPropName='colorfulIsOn' />

                </li>
                <li className="gi-6"></li>
                <li className="gi-7"></li>
                <li className="gi-8"></li>
                <li className="gi-9"></li>

                <li className="gi-10">
                <Link to="/">
                <button className="settings__resetGameBtn"
                    onClick={() => {
                        dispatch({ type: 'RESET_GAME' });
                    }
                    }>Reset game</button></Link>
                </li>
                <li className="gi-11"></li>
                <li className="gi-12"></li>
            </ul>

        </div>
    );
};

export default Settings;