import React from 'react';
import faces from '../game/faces';

const HowToPlay = () => {
    return (
        <div className="howToPlay">
            <h1>THE RULES OF YAHTZEE <span>SIMPLY EXPLAINED AND ILLUSTRATED</span></h1>

            <article>
                <h2>Left section</h2>
                <h3>Get points by adding all dice with numbers of the corresponding box</h3>
                <section>
                    <p><strong>ONES</strong> box - This example will give 4 points. <br />1 + 1 + 1 + 1 = 4.</p>
                    <div className="howToPlay__dGroup">
                        <figure className="dice">{faces.f1}</figure>
                        <figure className="dice">{faces.f1}</figure>
                        <figure className="dice">{faces.f1}</figure>
                        <figure className="dice">{faces.f1}</figure>
                        <figure className="dice">{faces.f5}</figure>
                    </div>
                </section>
                <section>
                    <p><strong>TWOS</strong> box - This example will give 6 points. <br />2 + 2 + 2 = 6.</p>
                    <div className="howToPlay__dGroup">
                        <figure className="dice">{faces.f2}</figure>
                        <figure className="dice">{faces.f2}</figure>
                        <figure className="dice">{faces.f2}</figure>
                        <figure className="dice">{faces.f4}</figure>
                        <figure className="dice">{faces.f6}</figure>
                    </div>
                </section>
                <section>
                    <p><strong>THREES</strong> box - This example will give 9 points. <br />3 + 3 + 3 = 9.</p>
                    <div className="howToPlay__dGroup">
                        <figure className="dice">{faces.f1}</figure>
                        <figure className="dice">{faces.f1}</figure>
                        <figure className="dice">{faces.f3}</figure>
                        <figure className="dice">{faces.f3}</figure>
                        <figure className="dice">{faces.f3}</figure>
                    </div>
                </section>
                <p>And the same principle is applied in boxes for FOURS, FIVES and SIXES.</p><br />
                <p>If sum of left section is <strong>63</strong> or more you will get a <strong>bonus of 35 points</strong>.</p>
            </article>


            <article>
                <h2>Right section</h2>

                <section>
                    <h3>3 OF A KIND</h3>
                    <p><strong>Sum of all dice</strong>. Requires at least 3 dice is the same.</p>
                    <p>This example will give 12 points</p>
                    <div className="howToPlay__dGroup">
                        <figure className="dice">{faces.f1}</figure>
                        <figure className="dice">{faces.f2}</figure>
                        <figure className="dice">{faces.f3}</figure>
                        <figure className="dice">{faces.f3}</figure>
                        <figure className="dice">{faces.f3}</figure>
                    </div>
                </section>

                <section>
                    <h3>4 OF A KIND</h3>
                    <p><strong>Sum of all dice.</strong> Same as 3 OF A KIND, but requires at least 4 dice is the same.</p>
                </section>

                <section>
                    <h3>FULL HOUSE</h3>
                    <p>3 of the same plus 2 of the same but another number.</p>
                    <p>This will always give <strong>25 points</strong>.</p>
                    <div className="howToPlay__dGroup">
                        <figure className="dice">{faces.f1}</figure>
                        <figure className="dice">{faces.f1}</figure>
                        <figure className="dice">{faces.f1}</figure>
                        <figure className="dice">{faces.f3}</figure>
                        <figure className="dice">{faces.f3}</figure>
                    </div>
                </section>

                <section>
                    <h3>SMALL STRAIGHT</h3>
                    <p>4 sequential dice. This will always give <strong>30 points</strong>.</p>
                    <p>Example of SMALL STRAIGHT</p>
                    <div className="howToPlay__dGroup">
                        <figure className="dice">{faces.f2}</figure>
                        <figure className="dice">{faces.f3}</figure>
                        <figure className="dice">{faces.f4}</figure>
                        <figure className="dice">{faces.f5}</figure>
                        <figure className="dice">{faces.f5}</figure>
                    </div>
                </section>

                <section>
                    <h3>LARGE STRAIGHT</h3>
                    <p>Same as SMALL STRAIGHT but requires 5 sequential dice.</p>
                    <p>This will always give <strong>40 points</strong>.</p>
                </section>

                <section>
                    <h3>YAHTZEE</h3>
                    <p>All 5 of the same number. This will always give <strong>50 points</strong>.</p>
                    <p>Example of YAHTZEE</p>
                    <div className="howToPlay__dGroup">
                        <figure className="dice">{faces.f2}</figure>
                        <figure className="dice">{faces.f2}</figure>
                        <figure className="dice">{faces.f2}</figure>
                        <figure className="dice">{faces.f2}</figure>
                        <figure className="dice">{faces.f2}</figure>
                    </div>
                </section>

                <section>
                    <h3>CHANCE</h3>
                    <p><strong>Simply sum of all dice</strong></p>
                    <p>This example of CHANCE will give 21 points</p>
                    <div className="howToPlay__dGroup">
                        <figure className="dice">{faces.f2}</figure>
                        <figure className="dice">{faces.f4}</figure>
                        <figure className="dice">{faces.f4}</figure>
                        <figure className="dice">{faces.f5}</figure>
                        <figure className="dice">{faces.f6}</figure>
                    </div>
                </section>
            </article>

        </div>);
};

export default HowToPlay;