const state = {
    rollBtnActive: true,
    rollsLeft: 3,
    roundsLeft: 13, // 13
    dices: [
        { id: 0, face: 0, active: false, rotation: 0 },
        { id: 1, face: 1, active: false, rotation: 0 },
        { id: 2, face: 2, active: false, rotation: 0 },
        { id: 3, face: 3, active: false, rotation: 0 },
        { id: 4, face: 4, active: false, rotation: 0 }
    ],
    scorecard: {

        ones: -1,
        twos: -1,
        threes: -1,
        fours: -1,
        fives: -1,
        sixes: -1,
        bonus: 0,
        toak: -1,
        foak: -1,
        fh: -1,
        ss: -1,
        ls: -1,
        yz: -1,
        ch: -1,

       /*  ones: 2,
        twos: 4,
        threes: 9,
        fours: 12,
        fives: 20,
        sixes: 18,
        bonus: 0,
        toak: 20,
        foak: 20,
        fh: 25,
        ss: 30,
        ls: 40,
        yz: 50,
        ch: 17, */

    },
    possibles: {
        ones: 0,
        twos: 0,
        threes: 0,
        fours: 0,
        fives: 0,
        sixes: 0,
        toak: 0,
        foak: 0,
        fh: 0,
        ss: 0,
        ls: 0,
        yz: 0,
        ch: 0
    },
    snackbar: { isVisible: false, message: '' },
    splash: { isVisible: true },
    wow: { isVisible: false, name: '' },
    settings: { soundIsOn: false, colorfulIsOn: true }

};

export default state;