import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../App';

const Finished = () => {

    const { state, dispatch } = useContext(AppContext);

    if (state.roundsLeft !== 0) return '';

    const sumAll = Object.values(state.scorecard).reduce((sum, num) => sum + num, 0);

    const getWellDoneText = (points) => {

        if (points > 300) return 'WOW!!! Very well done! 😎';
        else if (points > 250) return 'Nice! Very good! 😀';
        else if (points > 200) return 'Good job! 😉 ';
        else if (points > 150) return 'Not bad. Not bad at all. 😏';
        else return 'Super bad luck this time 😞';
    };

    return (
        <div className="finished">
            <h3>Game Finished</h3>
            <p className="finished__wellDoneText">{getWellDoneText(sumAll)}</p>
            <Link to="/results" className="gotoScore_link">
                <button>check score</button>
            </Link>
            <button className="finished__restartNowBtn"
            onClick={ () =>  dispatch({type: 'RESET_GAME'}) } 
            >Restart now</button>
        </div>
    );
};

export default Finished;


