import roll from './functions/roll';
import setScore from './functions/set_score';
import clickDice from './functions/click_dice';
import setWowVisible from './functions/set_wow_visible';
import initGameState from '../state/game';

const reducer = (state, action) => {

  switch (action.type) {
    case 'ROLL':
      return roll(state);
    case 'SET_SCORE':
      const newState = setScore(action.payload, state);
      const wow = setWowVisible(state.scorecard, newState.scorecard, action.payload);
      return { ...newState, wow: wow };
    case 'CLICK_DICE':
      return clickDice(state, action.payload);
    case 'RESET_GAME':
      return { ...initGameState, splash: state.splash, settings: state.settings };
    case 'SNACKBAR':
      return { ...state, snackbar: action.payload };
    case 'SPLASH':
      return { ...state, splash: { isVisible: false } };
    case 'SETTINGS':
      return { ...state, settings: { ...state.settings, ...action.payload } };
    case 'WOW':
      return { ...state, wow: { isVisible: false, name: '' } };
    default:
      throw new Error('reduer error');
  }
};

export default reducer;
