const getSortedUnique = (arr) => {
    const clone = JSON.parse(JSON.stringify(arr));
    const sorted = clone.sort();
    var unq = [];
    for (let i = 0; i < sorted.length; i++) {
        if (!unq.includes(sorted[i])) unq.push(sorted[i]);
    }
    return unq;
};

export default getSortedUnique;