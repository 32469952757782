import React, { useContext } from 'react';
import {AppContext} from '../../App';
import faces from './faces';

const Dices = () => {

    const { state, dispatch } = useContext(AppContext);

    const activeDices = state.dices
        .filter(item => item.active);

    const lockedDices = state.dices
        .filter(item => !item.active)
        .sort((a, b) => a.face - b.face);

    return (<section>
        <ul className="activeDices">{activeDices.map((item, i) =>
            <li key={i} className={'dice dice--active dice--' + item.id}
                style={{
                    'transform': 'rotate(' + item.rotation + 'deg)',
                    'top': (item.rotation / 360 * 100) + '%'
                }}
                onClick={() => dispatch({ type: 'CLICK_DICE', payload: String(item.id) })}>
                {faces['f' + (item.face + 1)]}</li>)}</ul>

        <ul className="lockedDices">{lockedDices.map((item, i) =>
            <li key={i} className={'dice dice--locked dice--' + item.id}
                onClick={(e) => dispatch({ type: 'CLICK_DICE', payload: String(item.id) })}>
                {faces['f' + (item.face + 1)]}</li>)}</ul></section>);
};

export default Dices;