import React, { useContext } from 'react';
import { AppContext } from '../../App';

const Scorecard = () => {

    const { state, dispatch } = useContext(AppContext);
    const sc = state.scorecard;
    const poss = state.possibles;

    const dpch = (payload) => dispatch({ type: 'SET_SCORE', payload });
    const points = (type) => { return sc[type] !== -1 ? sc[type] : poss[type] };
    const setClassTR = (type) => { return sc[type] === -1 ? 'active' : '' };

    return (
        <section className="scorecard-section">
        <div className="scorecard">
            <table className="scorecard__1">
                <tbody>
                    <tr className={setClassTR('ones')} onClick={() => dpch('ones')}><td>Ones</td><td>{points('ones')} </td></tr>
                    <tr className={setClassTR('twos')} onClick={() => dpch('twos')}><td>Twos</td><td>{points('twos')} </td></tr>
                    <tr className={setClassTR('threes')} onClick={() => dpch('threes')}><td>Threes</td><td>{points('threes')} </td></tr>
                    <tr className={setClassTR('fours')} onClick={() => dpch('fours')}><td>Fours</td><td>{points('fours')} </td></tr>
                    <tr className={setClassTR('fives')} onClick={() => dpch('fives')}><td>Fives</td><td>{points('fives')} </td></tr>
                    <tr className={setClassTR('sixes')} onClick={() => dpch('sixes')}><td>Sixes</td><td>{points('sixes')} </td></tr>
                    <tr onClick={ () => dispatch({type: 'SNACKBAR', payload: {isVisible: true, message: 'Score 63 or more on first section to get a bonus of 35 points'}}) }
                    ><td>Bonus</td><td>{sc.bonus}</td></tr>
                </tbody>
            </table>
            <table className="scorecard__2">
                <tbody>
                    <tr className={setClassTR('toak')} onClick={() => dpch('toak')}><td>3 of a kind</td><td>{points('toak')} </td></tr>
                    <tr className={setClassTR('foak')} onClick={() => dpch('foak')}><td>4 of a kind</td><td>{points('foak')} </td></tr>
                    <tr className={setClassTR('fh')} onClick={() => dpch('fh')}><td>Full house</td><td>{points('fh')} </td></tr>
                    <tr className={setClassTR('ss')} onClick={() => dpch('ss')}><td>Small straight</td><td>{points('ss')} </td></tr>
                    <tr className={setClassTR('ls')} onClick={() => dpch('ls')}><td>Large straight</td><td>{points('ls')} </td></tr>
                    <tr className={setClassTR('yz')} onClick={() => dpch('yz')}><td>Yahtzee</td><td>{points('yz')} </td></tr>
                    <tr className={setClassTR('ch')} onClick={() => dpch('ch')}><td>Chance</td><td>{points('ch')} </td></tr>
                </tbody>
            </table>
        </div>

        </section>
    );
};

export default Scorecard;