
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker'; // must be in this order
import './styles/style.scss';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-120806242-8');


ReactDOM.render(
  <React.StrictMode>
   <App /> 
  </React.StrictMode>,
  document.getElementById('root')
);

ReactGA.pageview(window.location.pathname + window.location.search);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();