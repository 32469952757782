import checkSumN from './calc/check_sum_n';
import checkNOfKind from './calc/check_n_of_kind';
import checkFullHouse from './calc/check_full_house';
import sumDices from './calc/sum_dices';
import checkStraights from './calc/check_straights';

const possibles = (faces) => {

    const sumFaces = sumDices(faces);

    const ones = checkSumN(faces, 0);
    const twos = checkSumN(faces, 1);
    const threes = checkSumN(faces, 2);
    const fours = checkSumN(faces, 3);
    const fives = checkSumN(faces, 4);
    const sixes = checkSumN(faces, 5);

    const yz = checkNOfKind(faces, 5) ? 50 : 0;
    const foak = yz || checkNOfKind(faces, 4) ? sumFaces : 0;
    const toak = foak || checkNOfKind(faces, 3) ? sumFaces : 0;

    const fh = checkFullHouse(faces);

    const ls = checkStraights(faces) === 'ls' ? 40 : 0;
    const ss = ls || checkStraights(faces) === 'ss' ? 30 : 0;

    const ch = sumFaces;

    return {
        ones, twos, threes, fours, fives, sixes,
        toak, foak, fh, ss, ls, yz, ch
    };
};

export default possibles;