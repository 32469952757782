import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {

    return (
        <div className="dashboard">
            <Link to="/settings"><i className="icon-cog"></i></Link>
            <Link to="/how-to-play"><i className="icon-help"></i></Link>
        </div>
    );

};

export default Dashboard;