const sumScorecard = (scorecard, limit) => {

    let sum = 0;
    let count = 0;

    for (const key in scorecard){
        count++;
        if (scorecard[key] !== -1) sum += scorecard[key]; 
        if(limit !== null && count === limit) return sum; 
    }
       
    return sum;
};

export default sumScorecard;