/* 
Input all faces as arr and required number of same faces.
checkSumN(faces, 4) will check 4 of a kind.
Return true or false.
*/

const checkNOfKind = (arr, n) => {
    let ns = [0,0,0,0,0,0];
    for(let i = 0; i < arr.length; i++){
        ns[arr[i]] = ns[arr[i]] + 1;
    }
    return ns.some( item => item === n );
};

export default checkNOfKind;