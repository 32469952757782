import initState from '../../state/game';
import sumScorecard from './calc/sum_scorecard';

const setScore = (payload, state) => {

    if(state.rollsLeft === 3) return state;
    if (state.scorecard[payload] !== -1) return state;

    const scVal = state.possibles[payload];

    const scorecard = { ...state.scorecard, [payload]: scVal };

    const bonus = sumScorecard(scorecard, 6) >= 63 ? 35 : 0;

    return {
        ...state,
        scorecard: { ...scorecard, bonus },
        roundsLeft: state.roundsLeft - 1,
        rollsLeft: 3,
        dices: initState.dices,
        possibles: initState.possibles
    };

};

export default setScore;

