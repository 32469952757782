
import React, { useContext } from 'react';
import RollsLeft from './rolls_left';
import { AppContext } from '../../App';
import Finished from './finished';

const RollBtn = () => {

    const { state, dispatch } = useContext(AppContext);

    return (
        <section className="rollBtn_section">

            <button className="rollBtn" onClick={() => dispatch({ type: 'ROLL' })}>Roll</button>
            <p className="setScoreText">Set your score</p>

            <Finished />

            <RollsLeft rollsLeft={state.rollsLeft} />
        </section>);
};

export default RollBtn;