import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import { Redirect, Link } from 'react-router-dom';

const Results = () => {

    const { state, dispatch } = useContext(AppContext);

    const names = ['Ones', 'Twos', 'Threes', 'Fours', 'Fives', 'Sixes', 'Bonus', '3 of a kind', '4 of a kind', 'Full house', 'Small straight', 'Large straight', 'Yahtzee', 'Chance'];
    const points = Object.values(state.scorecard).slice(0, 14);
    const [countP, setPoints] = useState(0);

    useEffect(() => {

        const sumP = points.reduce((sum, num) => sum + num, 0);

        const timeOut = setTimeout(() => {
            setPoints(countP + 1);
        }, 10);
        if (countP >= sumP) clearTimeout(timeOut);
    }, [countP, points]);

    if (state.roundsLeft !== 0) {
        return <Redirect to='/' />
    }

    return (<section className="result_section">

        <div className="results__tables">

            <table className="resultsTable"><tbody>
                {names
                    .filter((item, i) => i < 7)
                    .map((item, i) => {

                        const pointIsZero = points[i] === 0 ? 'resultsTable__row--isZero' : '';

                        return <tr key={i} className={`resultsTable__row ${pointIsZero}`}>
                            <td>{item}</td>
                            <td className={`td-${i}`}>{points[i] !== undefined ? points[i] : 0}</td>
                        </tr>
                    })}
            </tbody>
            </table>

            <table className="resultsTable"><tbody>
                {names
                    .filter((item, i) => i >= 7)
                    .map((item, i) => {

                        const offset = i + 7;
                        const pointIsZero = points[offset] === 0 ? 'resultsTable__row--isZero' : '';

                        return <tr key={i} className={`resultsTable__row ${pointIsZero}`}>
                            <td>{item}</td>
                            <td className={`td-${offset}`}>{points[offset] !== undefined ? points[offset] : 0}</td>
                        </tr>
                    })}
            </tbody>
            </table>

        </div>

        <div className="results__scoreBox">
            <p>Your score</p>
            <div className="results__scoreBox__holder">
                <h2>{countP}</h2>
            </div>
        </div>

        <Link to="/"><button className="results__playAgainBtn"
            onClick={() => dispatch({ type: 'RESET_GAME' })}
        >Play again</button></Link>

    </section>);
};

export default Results;