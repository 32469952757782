/*
 All faces as arr and n as the face value to sum
 Ex. checkSumN([0,0,0,1,2], 0) will sum all ones (value 0) and return 3  
 */

const checkSumN = (arr, n) => {
    return arr
        .filter((item) => item === n)
        .reduce((s, i) => s += i + 1, 0);
};

export default checkSumN;