import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../App';
import faces from '../game/faces';


const Splash = () => {

    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {

        if (state.splash.isVisible)
            setTimeout(() => {
                dispatch({ type: 'SPLASH' });
            }, 3000);

    }, [state, dispatch]);

    return (
        <div className={`splash splash--isVisible-${String(state.splash.isVisible)}`}>
            <div className="splash__inner">
                <h2>YAHTZEE APP</h2>
                <div className="splash__dices">
                    <li className="dice dice--0">{faces.f1}</li>
                    <li className="dice dice--1">{faces.f2}</li>
                    <li className="dice dice--2">{faces.f3}</li>
                    <li className="dice dice--3">{faces.f4}</li>
                    <li className="dice dice--4">{faces.f5}</li>
                </div>
            </div>
        </div>
    );

};

export default Splash;