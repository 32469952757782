import React, { useContext } from 'react';
import { AppContext } from '../../App';

const ToggleBtn = ({ isOn, settingsPropName }) => {

    const { state, dispatch } = useContext(AppContext);

    return (<div className={`toggleBtn toggleBtn--isOn-${isOn}`}
        onClick={() => dispatch({
            type: 'SETTINGS', payload: { [settingsPropName]: !state.settings[settingsPropName] }
        })
        }
    >
        <div className="toggleBtn__switch"></div>
    </div>);
};

export default ToggleBtn;