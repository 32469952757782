const clickDice = (state, id) => {

    if (state.rollsLeft === 3) return state;

    const dices = state.dices.map(item => {

        return (
            {
                ...item,
                active: String(item.id) === id ? !item.active : item.active
            }
        );
    });

    return { ...state, dices: dices };
};

export default clickDice;