import checkPossibles from './possibles';
import shufflesDices from './shuffled_dices';

const roll = (state) => {

  if (state.rollsLeft === 0) return state;

  const isStarted = state.rollsLeft < 3;
  const allLocked = state.dices.filter(item => !item.active).length === 5;
  if (isStarted && allLocked) return state;

  const dices = shufflesDices(state);

  const rollsLeft = state.rollsLeft - 1;
  const rollBtnActive = rollsLeft === 0 ? false : true;

  // debug
  /* let dices = state.dices;
    dices[0].face = 1;
    dices[1].face = 1;
    dices[2].face = 1;
    dices[3].face = 5;
    dices[4].face = 5; */

  const faces = dices.map((item) => item.face);
  const possibles = checkPossibles(faces);

  return {
    ...state,
    rollBtnActive,
    dices,
    rollsLeft,
    possibles
  };
};

export default roll;